/**
 * Created by Jeongbin on 2017-05-15.
 */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import $ from "jquery";
import "./Home.css";
import PriceBox from "./PriceBox";
import { withCookies } from "react-cookie";

import promotion_creator from "../../assets/img/promotion_creator.png";
import promotion_student from "../../assets/img/promotion_student.png";

//상품 선택 mouseOver
const boxMouseOver = (e) => {
  const { target } = e;
  if ($(target).hasClass("box")) {
    const boxes = $(target).parent().parent().find(".box");

    boxes.removeClass("on");
    $(target).addClass("on");
  }
};

const Home = (props) => {
  const { home, onChoice } = props;

  //const [toggler, setToggler] = useState(false);

  useEffect(() => {
    //onHome("RESET");
    //console.log(toggler);
    //setToggler(true)
  }, []);

  const onFontBook = (src) => {
    window.open("http://font.fontbada.co.kr/" + src);
  };

  // console.log("props");
  // console.log(props);

  //상품선택
  let selected = home.options[home.selected]; //option list
  // let select_option = home.selected;
  // let target = home.target;
  let color = home.color;

  // console.log("#cookies");
  // console.log(cookies);

  // let m_code = cookies.get("m_code");

  /**
   * 메인화면 인기서체
   */
  let features = {
    울릉도: { num: 150, type: 4 },
    모모고딕: { num: 64, type: 3 },
    텔리비젼: { num: 184, type: 1 },
    시네마천국: { num: 117, type: 1 },
    HY고딕: { num: 20, type: 5 },
    청춘스타: { num: 171, type: 1 },
    배틀아일랜드: { num: 83, type: 1 },
    천지인: { num: 168, type: 1 },
  };

  const FontFeatures = Object.keys(features).map((key) => (
    <Box key={key}>
      <div className="type">{features[key].type}종</div>
      <a
        href={`http://font.fontbada.co.kr/#/detail/${features[key].num}`}
        target="_new"
      >
        <img src={`/assets/img/p_${key}.png`} width="" alt={key} />
      </a>
    </Box>
  ));

  return (
    <>
      <div className="home">
        <ProductWrap>
          <ProductList>
            {" "}
            <ul className="product-list" onMouseOver={boxMouseOver}>
              <li>
                <div className="smart box on">
                  <h1>Smart </h1>
                  <div className="summary">
                    <p>
                      신규 폰트 정기 업데이트
                      <br />
                      모든 라이선스 제공
                      <span className="except">(임베디드, 웹폰트 제외)</span>
                    </p>
                  </div>
                  <div className="price">
                    <p>상업용</p>
                    <span
                      onClick={() => {
                        onFontBook("smart");
                      }}
                    >
                      제공폰트 보기
                    </span>
                  </div>
                  <span
                    className="btn"
                    onClick={() => {
                      onChoice("HOME_CHOICE", "smart");
                    }}
                  >
                    자세히 보기
                  </span>
                </div>
              </li>

              <li>
                <div className="cad box">
                  <h1>CAD</h1>
                  <div className="summary">
                    <p>
                      신규 폰트 정기 업데이트 <br />
                      CAD 전용 라이선스
                    </p>
                  </div>
                  <div className="price">
                    <p>상업용</p>
                    <span
                      onClick={() => {
                        onFontBook("cad");
                      }}
                    >
                      제공폰트 보기
                    </span>
                  </div>
                  <span
                    className="btn"
                    onClick={() => {
                      onChoice("HOME_CHOICE", "cad");
                    }}
                  >
                    자세히 보기
                  </span>
                </div>
              </li>

              <li>
                <div className="webtoon box">
                  <h1>Webtoon</h1>
                  <div className="summary">
                    <p>
                      웹툰폰트 11종
                      <br />
                      웹툰전용 라이선스 제공
                    </p>
                  </div>
                  <div className="price">
                    <p>상업용</p>
                    <span
                      onClick={() => {
                        onFontBook("webtoon");
                      }}
                    >
                      제공폰트 보기
                    </span>
                  </div>
                  <span
                    className="btn"
                    onClick={() => {
                      onChoice("HOME_CHOICE", "webtoon");
                    }}
                  >
                    자세히 보기
                  </span>
                </div>
              </li>

              <li>
                <div className="solo box ">
                  <h1>Solo</h1>
                  <div className="summary">
                    <p>
                      신규폰트 정기 업데이트
                      <br />
                      모든 라이선스 제공
                      <span className="except">(임베디드, 웹폰트 제외)</span>
                    </p>
                  </div>
                  <div className="price">
                    <p>비상업용</p>

                    <span
                      onClick={() => {
                        onFontBook("solo");
                      }}
                    >
                      제공폰트 보기
                    </span>
                  </div>
                  <span
                    className="btn"
                    onClick={() => {
                      onChoice("HOME_CHOICE", "solo");
                    }}
                  >
                    자세히 보기
                  </span>
                </div>
              </li>

              <li>
                <div className="master box">
                  <h1>Font Care</h1>
                  <div className="summary">
                    <p>
                      폰트개발 및 지원이 필요한 <br />
                      기업 및 단체
                    </p>
                  </div>
                  <div className="price">
                    <p>데모킷 제공</p>
                  </div>
                  <a href="/fontcare.php">
                    <span className="btn">자세히 보기</span>
                  </a>
                </div>
              </li>
            </ul>
          </ProductList>
        </ProductWrap>
        <Information>
          {" "}
          <Messages fontSize="32px" color="#282828" paddingTop="30px">
            사용인원에 해당하는 상품을 구매하여 사용하세요.
          </Messages>
          <PriceBox 
            choice={selected} 
            home={home} 
            color={color} 
            selected={home.selected} 
          />
          <Messages
            fontSize="29px"
            color="red"
            marginTop="10px"
            paddingBottom="30px"
          >
            {/* *과거 사용된 결과물은 소급되지 않습니다 .(별도계약) */}
          </Messages>
          <div>
            {/* <button onClick={() => setToggler(!toggler)}> */}
            <a
              href="/img/popup/license_information.png"
              data-lightbox="promotion"
              data-title="법무법인 공문확인"
              id="promotion_banner"
            >
              <img src="/assets/img/official_notice.png" alt="notice" />
            </a>
            {/* </button> */}
          </div>
          <License>
            <div>
              <Licensedivs>
                <span>
                  <a href="#!" onClick={() => onFontBook("all")}>
                    <img src="/assets/img/shortcut1.png" alt="" />
                  </a>
                </span>
                <span>
                  <Link to={{ pathname: "/download" }}>
                    <img src="/assets/img/shortcut2.png" alt="" />
                  </Link>
                </span>

                <span>
                  <a
                    href="https://www.fontbada.co.kr/information.php"
                    target="_new"
                  >
                    <img src="/assets/img/shortcut3.png" alt="" />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.fontbada.co.kr/customer.php"
                    target="_new"
                  >
                    <img src="/assets/img/shortcut4.png" alt="" />
                  </a>
                </span>
              </Licensedivs>
            </div>
          </License>
          {/* <Promotion>
            <div>
              <a href="http://promotion.fontbada.co.kr" target="_blank" rel="noreferrer" >
                <img src={promotion_creator} alt="creator promotion" />
              </a>
            </div>
            <div>
              <a href="/apply/soloEdu">
                <img src={promotion_student} alt="student promotion" />
              </a>
            </div>
          </Promotion> */}
          <Messages
            fontSize="47px"
            color="#282828"
            align="center"
            marginTop="80px"
          >
            수 많은 HY폰트 사용자들이 자주 사용하는 폰트
          </Messages>
          <Messages fontSize="30px" color="#757473" align="center">
            일관되고 안정적인 글꼴사용은 높은 가치를 더해 줍니다. <br />
            HY폰트의 다양한 글꼴은 오랜 세월 그 가치를 제공합니다.
          </Messages>
          <Boxes>{FontFeatures}</Boxes>
        </Information>
      </div >
    </>
  );
};

const ProductWrap = styled.div`
  background-color: #fff;
`;

const ProductList = styled.div`
  width: 1344px;
  margin: 0 auto;
`;

const Information = styled.div`
  background-color: #fff;
  width: 1344px;
  margin: 0 auto;
`;

const Boxes = styled.div`
  display: flex;
  height: 630px;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 90px;
  flex-flow: wrap;
  justify-content: space-between;
  align-content: space-between;
`;

const Box = styled.div`
  background: #fff;
  color: #666;
  flex-basis: 303px;
  height: 303px;
  margin-top: 10px;
  font-size: 22px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #dfddde;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin-right: 35px;
  &:hover {
    background: #ececec;
  }
  align-items: center;
  position: relative;

  .type {
    width: 30px;
    font-size: 16px;
    position: absolute;
    display: flex;
    right: 10px;
    color: #666;
    top: 10px;
  }
`;

const Messages = styled.div`
  background-color: #fff;
  font-family: HY울림고딕B, sans-serif;
  line-height: 1.6;

  font-size: ${(props) => `${props.fontSize ? props.fontSize : ""}`};
  color: ${(props) => `${props.color ? props.color : ""}`};

  margin-top: ${(props) => `${props.marginTop ? props.marginTop : ""}`};
  padding-bottom: ${(props) =>
    `${props.paddingBottom ? props.paddingBottom : "26px"}`};

  padding-top: ${(props) => `${props.paddingTop ? props.paddingTop : ""}`};

  display: flex;
  flex-direction: column;
  position: relative;

  align-items: ${(props) => `${props.align ? props.align : ""}`};
`;

const Promotion = styled.div`
  display: flex;
  justify-content: space-between;
`;

const License = styled.div`
  display: flex;
  padding: 46px 0 46px 0;
  justify-content: center;
`;
const Licensedivs = styled.div`
  font-family: HY고딕L, sans-serif;
  display: grid;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-template-columns: repeat(4, 155px);
  row-gap: 48px;
  column-gap: 29px;

  & > p {
    display: flex;
    flex-direction: column;
    justify-content: left;
    border-radius: 5px;
  }

  ul {
    width: 180px;
    display: flex;
    justify-content: left;
    font-size: 14px;

    line-height: 1.8;
    flex-direction: column;
    padding-top: 30px;
    margin-left: 10px;
  }

  .warning {
    font-family: HY고딕L, sans-serif;
    color: red;
    font-size: 14px;
    margin-left: 10px;
  }

  li {
    font-size: 14px;
    list-style: disc;
    list-style-position: inside;
    padding-left: 3px;
    line-height: 180%;
  }

  div > div {
    flex-basis: 190px;
    width: 188px;
    height: 60px;
    background: #ececec;
    font-size: 20px;
    display: flex;
    justify-content: center;

    align-items: center;
    flex-direction: column;
    padding: 6px;
    border-radius: 3px;

    span {
      font-family: HY고딕L, sans-serif;
      font-size: 15px;
    }
  }
`;

export default withCookies(Home);
