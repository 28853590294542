/**
 * Created by Jeongbin on 2017-11-21.
 */
/**
 * Created by Jeongbin on 2017-06-19.
 */
import React from "react";

import styled from "styled-components";
import logo from "../../assets/img/top-logo.png";

import { withCookies } from "react-cookie";

const onAlert = () => {
  //alert('준비중입니다');
  window.location.href = "http://font.fontbada.co.kr/";
};

const HeaderSubIndex = (props) => {
  const { cookies } = props;

  // console.log("cookies");
  // console.log(cookies.cookies.m_code);
  // console.log(cookies);

  const m_code = cookies.cookies.m_code;

  // console.log("### header sub index ####");
  // console.log(m_code);

  let icon_login = "";
  let icon_mypage = "";

  if (!m_code) {
    icon_login = (
      <a
        href="/login.php"
        data-tooltip-text="로그인"
        className="tooltips tooltip-bottom-login"
      >
        <span id="nav-sub-menu3"></span>
      </a>
    );
    icon_mypage = "";
  } else {
    icon_mypage = (
      <a
        href="/mypage.service.list.php"
        data-tooltip-text="마이페이지"
        className="tooltips  tooltip-bottom"
      >
        <span id="nav-sub-menu5"></span>
      </a>
    );
    icon_login = (
      <a
        href="/logout.php"
        data-tooltip-text="로그아웃"
        className="tooltips  tooltip-bottom-login"
      >
        <span id="nav-sub-menu4"></span>
      </a>
    );
  }

  return (
    <div className="subHeader">
      <div className="nav">
        <div className="nav-logo">
          <a href="/" id="top-logo">
            <Logo />
          </a>
        </div>
        <div className="nav-menu">
          <a
            href="/license.php"
            data-tooltip-text="고객센터"
            className="tooltips  tooltip-bottom"
          >
            <span id="nav-sub-menu1"></span>
          </a>
          <a
            href="#!"
            onClick={() => onAlert()}
            onFocus={() => this.blur()}
            data-tooltip-text="&nbsp;폰트"
            className="tooltips  tooltip-bottom"
          >
            <span id="nav-sub-menu2"></span>
          </a>
          <a
            href="/download.php"
            className="tooltips  tooltip-bottom"
            data-tooltip-text="폰트매니져"
          >
            <span id="nav-sub-menu6"> </span>
          </a>
          {icon_mypage}
          {icon_login}
        </div>
      </div>
    </div>
  );
};

export const Logo = styled.div`
  background-image: url(${logo});
  background-size: cover;
  display: block;
  margin: 0 auto;
  text-indent: -9999px;
  width: 80px;
  height: 36px;
`;

export default withCookies(HeaderSubIndex);
