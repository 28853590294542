/**
 * Created by Jeongbin on 2017-06-19.
 */
import React, { Component } from "react";

import styled from "styled-components";
import logo from "../../assets/img/top-logo.png";

import { withCookies } from "react-cookie";

class HeaderIndex extends Component {
  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      m_code: cookies.get("m_code") || "",
    };
  }

  // componentWillMount() {
  //   //this.state =  { userId: cookie.load('m_uid') }
  //   this.setState({userId: cookie.load('m_uid')})
  // }

  render() {
    const { m_code } = this.state;

    let icon_login = "";
    let icon_mypage = "";

    if (!m_code) {
      icon_login = (
        <a
          href="/login.php"
          data-tooltip-text="로그인"
          className="tooltips tooltip-bottom-login"
        >
          <span id="nav-sub-menu3"> </span>
        </a>
      );
      icon_mypage = "";
    } else {
      icon_login = (
        <a
          href="/logout.php"
          data-tooltip-text="로그아웃"
          className="tooltips tooltip-bottom-login"
        >
          <span id="nav-sub-menu4"></span>
        </a>
      );
      icon_mypage = (
        <a
          href="/mypage.service.list.php"
          data-tooltip-text="마이페이지"
          className="tooltips tooltip-bottom"
        >
          <span id="nav-sub-menu5"></span>
        </a>
      );
    }

    const onAlert = () => {
      //alert('준비중입니다');

      window.location.href = "http://font.fontbada.co.kr";
    };

    return (
      <MainHeader>
        <NavTop>
          <Nav>
            <nav className="navbar">
              <div className="navbar-header">
                <div className="nav-logo">
                  <a href="/" id="top-logo">
                    <Logo />
                  </a>
                </div>
              </div>

              <div className="navbar-body">
                <div className="nav-menu">
                  <ul id="navlist">
                    <a href="/information.php" 
                       className="tooltips tooltip-bottom" 
                       data-tooltip-text="고객센터">
                      <span id="nav-sub-menu1"> </span>
                    </a>
                    <a href="#!" 
                       className="tooltips tooltip-bottom" 
                       data-tooltip-text="폰트">
                      <span id="nav-sub-menu2"> </span>
                    </a>
                    <a href="/download.php" 
                       className="tooltips tooltip-bottom" 
                       data-tooltip-text="폰트매니져">
                      <span id="nav-sub-menu6"> </span>
                    </a>
                    <a href="/login.php" 
                       className="tooltips tooltip-bottom-login" 
                       data-tooltip-text="로그인">
                      <span id="nav-sub-menu3"> </span>
                    </a>
                  </ul>
                </div>
              </div>
            </nav>
          </Nav>
        </NavTop>

        <div className="mainBanner">
          <div className="bannerText">
            언제 어디서나 편리한 폰트 사용 <br />
            폰트바다가 함께합니다 <br />
          </div>
        </div>
      </MainHeader>
    );
  }
}

export const Logo = styled.div`
  background-image: url(${logo});
  background-size: cover;
  display: block;
  margin: 0 auto;
  text-indent: -9999px;
  width: 80px;
  height: 36px;
`;

const MainHeader = styled.div`
  background-color: #fff;
`;

const Nav = styled.div`
  position: relative;
  width: 1344px;
  margin: 0 auto;    
`;

const NavTop = styled.div`
  width: 100%;
  background-color: #184b68;
  z-index: 1000;
  position:relative;
`;

export default withCookies(HeaderIndex);
