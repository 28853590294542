/**
 * Created by Jeongbin on 2017-06-19.
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Select from "react-select";

import { NumericFormat } from "react-number-format";
import license_cad from "../../assets/img/licenses_cad.png";
import license_solo from "../../assets/img/licenses_solo.png";
import license_webtoon from "../../assets/img/licenses_webtoon.png";
import licenses_mookhyang from "../../assets/img/licenses_mookhyang.png";

const Company = (props) => {
  const { types, params, company, user } = props;
  const { onUser, onCompany } = props;

  const [choiceUserCount, setChoiceUserCount] = useState(0);

  const Codes = {
    trial: "trial",
    webtoon1: "webtoon",
    cad1: "cad",
    solo1: "solo",
    soloEdu: "solo",
    smart1: "smart",
    smart25: "smart",
    smart50: "smart",
    master: "master",
    mookhyang: "mookhyang",
  };

  let userSelected = "";


  if (params.option) {
    userSelected = params.option; // param상품 상세코드 ,
  } else {
    userSelected = company.selected; // 기록하고 있는 상세코드
  }

  // 1 . userSelected 선택해서 넘어온 값 
  let product = Codes[userSelected]; // 변환 smart, solo,webtoon....

  let productProps = types[product]; // 상품상세

  // 사용자 수
  let userCount = user.selected;

  // 옵션 리스트
  let prdOptions = productProps.options ? productProps.options : "";

  let prdSelected = userSelected ? prdOptions[`${userSelected}`] : ""; // 선택한 상품 정보

  // webtoon과 cad의 경우 선택한 인원수로 copy 값 업데이트
  if (["webtoon", "cad"].includes(product)) {
    prdSelected = { ...prdSelected, copy: userCount.toString() };
  }

  //프로모션 구분
  if (company.promotion) {
    let prices = [{ label: '', value: company.price, type: company.type, promotion: company.promotion }]
    prdSelected = { ...prdSelected, periodType: company.type, prices };
  } else {
    prdSelected = { ...prdSelected, periodType: company.type };
  }



  props = { ...props, product };


  // console.log(prdSelected)

  useEffect(() => {
    onCompany("CHANGE", {
      product: product,
      selected: params.option,
      label: prdSelected.prices[0].label,
      price: prdSelected.prices[0].value,
      type: prdSelected.prices[0].type,
      copy: userCount
    });
  }, [product, userCount]);


  /**
   * 
   * @param {*} props 
   */

  const radioChoice = (props) => {


    let choiceValue =
      props === "year" ? prdSelected.prices[0] : prdSelected.prices[1];

    onCompany("CHANGE", {
      label: choiceValue.label,
      type: choiceValue.type,
      price: choiceValue.value,
    });
  };

  //결제방법선택
  const Product = (props) => {

    let { prices, periodType } = props

    return Object.keys(prices).map((key) => (
      <RadioGroup className="option" key={key}>
        <input
          type="radio"
          name="period"
          id={key}
          onChange={(e) => {
            radioChoice(e.target.value);
          }}
          value={prices[key].type}
          checked={prices[key].type === periodType ? true : false}
        />
        <label
          htmlFor={key}
          className={key}
          onChange={(e) => {
            radioChoice(e.target.value);
          }}
        >
          <div className={`radio-area solo`}>
            <span>
              <span className={company.selected}> {""}</span>
            </span>
          </div>
          <div className="info">
            <p>
              {prices[key].label} <span className="price">₩</span>
              <NumericFormat
                displayType={"text"}
                className="price"
                thousandSeparator={true}
                value={prices[key].value * userCount}
              />{" "}
              <span className="vat">(vat포함)</span>
              <br />
              {
                prices[key].type === "year" ? "1년 후 갱신결제" :
                  prices[key].type === "month" ? "매월 자동 결제 (1년/ 12회)" :
                    prices[key].type === "unlimited" && prices[key].promotion ?
                      (<span>1인사용 / <span className="red">55%할인</span></span>) :
                      (<span>1인사용</span>)
              }

            </p>
          </div>
        </label>
      </RadioGroup>
    ));
  };

  /**
   *
   * @param {*} props
   * @returns
   * trial 결제 선택
   */
  const ProductTrial = (props) => (
    <RadioGroup className="option">
      <input
        type="radio"
        name="period"
        id="1"
        defaultValue
        checked
        onChange={(option) => { }}
      />

      <label>
        <div className={`radio-area solo`}>
          <span>
            <span className="solo"> {""}</span>
          </span>
        </div>

        <div className="info">
          <p>
            {prdSelected === "trial" ? "30일 사용권" : "1년 사용권(무료)"}{" "}
            <br />
          </p>
        </div>
      </label>
    </RadioGroup>
  );

  /**
   * 라이선스 사용자수 선택
   * @param {*} props 
   * @returns 
   */
  const User = (props) => {

    let defaultValue = props.options[0];
    let options = props.options;

    return ["webtoon", "cad"].includes(product) ? (
      <Select
        name="UserCount"
        defaultValue={defaultValue}
        value={options.find((op) => {
          return op.value === choiceUserCount;
        })}
        placeholder="선택해주세요."
        options={options}
        onChange={(option) => {
          onUser("CHANGE", option);
          setChoiceUserCount(option.value);
        }}
      />

    ) : (
      "  "
    );
  };

  const license_img = ["cad"].includes(product) ? license_cad
    : ["webtoon"].includes(product) ? license_webtoon
      : ["smart", "solo"].includes(product) ? license_solo
        : ["mookhyang"].includes(product) ? licenses_mookhyang
          : "";



  const copyCount = prdSelected.copy + "인"
  const licenseYear = "1년 약정"
  const licenseRange = " - 비상업용(납품불가)"
  const productName = prdSelected.name

  return (<Wrapper>
    <BoxWrapper>
      <Grid>
        <Item fontSize="18" bold>
          {productName}
        </Item>
        <Item fontSize="18" bold>
          {["webtoon", "cad", "mookhyang"].includes(product) ? "" : copyCount}
          {["smart", "solo", "smart", "cad", "webtoon"].includes("product") ? licenseYear : ""}
          {["solo"].includes(product) ? licenseRange : ""}
        </Item>
        {["webtoon", "cad"].includes(product) ? (
          <>
            <Item bold>인원</Item>
            <Item>
              <User {...user} />
            </Item>
          </>
        ) : (
          ""
        )}
        <Item>
          <Payments>
            <FormGroup>
              {["trial", "soloEdu"].includes(userSelected) ? (
                <ProductTrial />
              ) : (
                <Product {...prdSelected} />
              )}
            </FormGroup>
          </Payments>
        </Item>
      </Grid>
    </BoxWrapper>
    <ul>
      <li>본 상품은 연단위로 제공되는 서비스입니다.</li>
      <li>본 상품은 결제와 동시에 해당 서비스가 제공됩니다.</li>
      <li>본 상품은 결제 후 청약철회 및 교환 / 환불이 불가합니다.</li>
      <li>
        본 상품은 타용도로 사용하거나 타인에게 양도 및 재생산
        <br />
        <span>할 수 없습니다.</span>{" "}
      </li>
      <li>
        본 상품은 과거 사용된 결과물은 소급 되지 않습니다.
        <br />
        <span>(별도 계약이 필요합니다.)</span>
      </li>
    </ul>
    <ImageBox>
      <div className="head">
        <div className="title">제공라이선스</div>
        <div className="link">
          <span>
            <a href="/download/license_scope.pdf" target="_new">
              상세보기 +
            </a>
          </span>
        </div>
      </div>
      <img src={license_img} alt="license Information" />
    </ImageBox>
  </Wrapper>)
};

const Payments = styled.div`
  width: 465px;

  ul {
    padding: 30px 0px 30px 0px;
  }

  ul li {
    font-family: HY고딕M, sans-serif;
    font-size: 14px;
    list-style-type: square;
    list-style-position: inside;
  }

  & h2 {
    font-family: HY고딕M, sans-serif;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 35px;
    font-weight: normal;
  }

  input:checked + label .radio-area.webtoon {
    background-color: #fcc728;
  }

  input:checked + label .radio-area.solo {
    background-color: #08cdd4;
  }

  input:checked + label .radio-area.cad {
    background-color: #cde736;
  }

  input:checked + label .radio-area.smart {
    background-color: #e81f61;
  }

  input:checked + label .radio-area span span {
    display: block;
  }

  input {
    display: none;
  }
`;
const FormGroup = styled.div`
  display: flex;  
  flex-direction:column;
  
  
  & .info {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;
    padding-left: 32px;


    & p {
    font-family: HY고딕L, sans-serif;
    font-size: 16px;
    line-height: 1.6;        
    padding: 5px;
        
    }

    .red {
      font-family: HY고딕M, sans-serif;
      font-size: 18px;
      color:red;
    }

    .bold {
    font-family: HY고딕M, sans-serif;
    font-size: 18px;
    }

    & .bankNum {
    font-family: HY고딕L,sans-serif;        
    font-size:16px;
    margin: 5px 0;
    }

    .vat {
      font-size:12px
    }
    
    .price {
      font-size:large;
      font-family: HY고딕B,sans-serif;
    }
    
      
  }

  & .radio-area {
    flex-basis: 60px;
    background-color: #edebee;
    display: flex;
    align-items: center;
    justify-content: center;

    span span {
      border-radius: 50%;
      background-color: #000000;
      width: 13px;
      height: 13px;
      display: none;
    }

    span {
      border-radius: 50%;
      background-color: #fefefe;
      width: 23px;
      height: 23px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

  }
  
}`;

const Wrapper = styled.div`
  margin-top: 110px;
  width: 450px;
  display: flex;
  flex-direction: column;
  ul {
    font-family: HY고딕M, sans-serif;
    font-size: 13px;
    padding: 20px 0px 20px 0px;
    margin-left: 10px;
    line-height: 150%;
  }

  ul li {
    list-style-type: disc;
    list-style-position: inside;
    padding-top: 1px;
    line-height: 180%;

    span {
      margin-left: 16px;
    }
  }
`;

const BoxWrapper = styled.div`
  width: 450x;
  border: 1px solid #dfdedf;
  border-radius: 5px;

  .label {
    width: 80%;
    height: 35px;

    border: 1px solid #dfdedf;
    display: flex;
    padding-left: 32px;
    font-family: HY고딕L, sans-serif;
    font-size: 16px;
    align-content: center;
    align-items: center;
  }

  .bankNum {
    font-family: HY고딕M, sans-serif;
    font-size: 13px;
    padding: 10px;
    line-height: 200%;
    margin-left: 15px;

    h1 {
      font-family: HY고딕B, sans-serif;
      font-size: 14px;
    }
  }
`;

const ImageBox = styled.div`
  padding: 30px 0 30px 0;
  .head {
    display: inline-flex;
    width: 430px;
    height: 50px;
    padding: 3px 0 3px 0;
    align-content: center;
    align-items: center;
  }

  .title {
    width: 40%;
    font-size: 18px;
  }

  .link {
    width: 100%;
    font-size: 12px;
    line-height: 30px;

    a:link {
      color: #08cdd4;
    }

    a:visited {
      color: #08cdd4;
    }
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-bottom: 14px;
`;

const Item = styled.div`
  display: flex;
  align-self: left;
  padding: 8px 10px 0px 8px;

  font-size: ${(props) => (props.fontSize ? props.fontSize : "14")}px;
  font-family: ${(props) =>
    props.bold
      ? "HY고딕B, sans-serif"
      : props.medium
        ? "HY고딕M, sans-serif"
        : "HY고딕L, sans-serif"};

  hr {
    display: block;
    width: 90%;
    border-top: 1px solid #eee;
  }

  .small {
    font-size: 10px;
    text-align: right;
    display: block;
    padding: 2px;
  }
`;

const RadioGroup = styled.div`
  width: 350px;
  display: ${(props) => (props.hidden ? "none" : "flex")};


  border-radius: 5px;
  border: 1px solid #dfddde;
  box-sizing: border-box;

  margin-top:10px;

  & label {
    display: flex;
    flex-grow: 1;
    cursor: pointer;
  }

  & .radio-area {
    flex-basis: 60px;
    background-color: #edebee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card {height:38px;}  
  .vbank {height:38px;}  
  .promotion {height:38px}  
  .trial {height:38px}  


}`;

export default Company;
