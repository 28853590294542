/**
 * Created by Jeongbin on 2017-06-21.
 */
import React, { useEffect } from "react";

//import "react-select/dist/react-select.css";
// import Bill from "./Bill";
import styled from "styled-components";
//import selectArrow from "../../assets/img/select-arrow.png";
import clearImage from "../../assets/img/clear.png";

const Bank = (props) => {
  //const [selectedOption, setSelectedOption] = useState("");
  const { receipts, usage, company } = props;
  const { onReceipt, onUsage } = props;

  let options = usage.type.options[usage.method.selected];
  let defaultValue = options[0];

  useEffect(() => {
    options = usage.type.options[usage.method.selected];
    onUsage("TYPE_CHANGE", defaultValue);
  }, [defaultValue]);

  // const arrowRenderer = () => {
  //   return <img src={selectArrow} alt="" />;
  // };

  let placeHolder = `${defaultValue.label} 를 입력해주세요.`;

  return (
    <ReceiptsWrap>
      <div className="bank-group">
        <div>
          {/* 영수증 선택 UI 제거 - 기본값으로 세금계산서 처리 */}
          <div className="form-inline receipts" style={{display: 'none'}}>
            <input 
              type="hidden" 
              name="receipts" 
              value="taxBill" 
            />
          </div>
        </div>
      </div>
    </ReceiptsWrap>
  );
};

const ReceiptsWrap = styled.div`
  align-items: flex-start;
  margin: 20px 0 20px;
  

  .form-control {
    flex-wrap: wrap;
    flex-basis: 0;
  }

  .form-inline {

    padding-top : 15px;

    & > span {
      font-size: 18px;
      flex-basis: 100px;
      color: #595757;
      height : 35px;
      display: block;
    } 

    &.usage {
      align-items: flex-start;
    }

  .input-group {
    margin-top: 15px;
    position:relative;

    img { 
      position: absolute;
      right: 10px;
      top: 10px;
    }


    .usage-select {
      height: 32px;
      font-size:medium; 
      &.is-focused:not(.is-open) > .Select-control {
        border-color: #dfdedf;
        box-shadow: none;
      }
  
      .Select-arrow-zone {
        img {
          vertical-align: middle;
        }
      }

    }

    input {
      display: inline-block;
      width: 100%;
      flex-basis: 100%;
      margin: 0;
      border: 1px solid #dfdedf;
      border-radius: 3px;
      font-family: HY고딕M, sans-serif;
      font-size: 16px;
      outline: none;
      height: 41px;
      box-sizing: border-box;
      padding-left: 10px;
      
    }
  }

  .radio-group {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;

    label {
      font-family: HY고딕L, sans-serif;
      font-size: 14px;
      padding: 2px;

      span {
        width: 14px;
        height: 14px;

        span {
          width: 8px;
          height: 8px;
        }
      }
    }

    --color: #fefefe;
    --bg-color: #08cdd4;
    --font-color:#666;
 

    input {
      display: inline-block;
      &:checked + label {
        font-family: HY고딕L, sans-serif;
      }
    }

    input[type='radio'],
    input[type='radio']:checked {
      appearance: none;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 100%;
      margin-right: 0.1rem;
    }

    input[type='radio'] {
      background-color: var(--color);
      border: 1px solid var(--font-color);
    }
    input[type='radio']:checked {
      background-color: var(--bg-color);
    }


  }
`;

export default Bank;
