import React from "react";
import { Link } from "react-router-dom";

import { NumericFormat } from "react-number-format";
import styled from "styled-components";

const PriceBox = ({ choice, home, color, selected }) => {
  // 값 확인을 위한 콘솔 로그 추가
  // console.log("PriceBox props:", { choice, home, color });
  // console.log("home 객체:", home);
  // console.log("choice 객체:", choice);
  // console.log("selected 값:", selected);

  // Legacy Price component
  const Price = (props) => {
    return (
      <div>
        {props.count !== "0" ? (
          <>
            <div>
              {props.title === "SOLO 학생용" ? (
                <div className="priceRow">
                  <span className="Year">일시납</span>
                  <span className="Price">
                    <span className="originalPrice">
                      ₩
                      <s>
                        <NumericFormat
                          value={99000}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </s>
                    </span>
                   
                  </span>
                </div>
              ) : (
                <>
                  <div className="priceRow">
                    <span className="Year">월  납</span>
                    <span className="Price">
                      ₩
                      <NumericFormat
                        value={props.monthPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </span>
                  </div>
                  <div className="priceRow">
                    <span className="Year">일시납</span>
                    <span className="Price">
                      ₩
                      <NumericFormat
                        value={props.price}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </span>
                  </div>
                </>
              )}
            </div>
            {/* price가 0보다 큰 경우에만 span 출력 */}
            {props.price > 0 && (
              <span>{props.count}인 사용 / 12개월 약정</span>
            )}
          </>
        ) : (
          <div>
            <span className="callus">
              02-598-0050 <br />
              (별도문의)
            </span>
          </div>
        )}
      </div>
    );
  };

  // BuyButton component
  const BuyButton = (props) => {
    let path = "/apply/" + props.product;

    return props.count !== "0" ? (
      <Link
        to={{
          pathname: path,
          query: { type: selected, count: props.count },
        }}
      >
        <Btn>구매하기</Btn>
      </Link>
    ) : (
      <span className="consultOnly">상담전용상품</span>
    );
  };

  // 상세 상품 버튼
  const SelectButton = (props) => {
    /**
     * smartPlus는 php를 통해 내용을 접수 받음
     */
    let path =
      props.product === "smartPlus"
        ? "/customer.php"
        : "/apply/" + props.product;

    return props.product !== "smartPlus" ? (
      <Link
        to={{
          pathname: path,
          query: { type: selected, count: props.count },
        }}
      >
        <img src={`/assets/img/box_${props.product}.png`} alt="" />
      </Link>
    ) : (
      <a href={path} target="_self">
        <img src={`/assets/img/box_${props.product}.png`} alt="" />
      </a>
    );
  };

  // Legacy ChoiceList
  const ChoiceList = Object.keys(choice).map((key, index) => (
    <PriceItem key={index}>
      <div className="title">{choice[key].title}</div>
      <div className="price">
        <Price {...choice[key]} />
      </div>
      <div className="desc">{choice[key].desc}</div>
      {(selected === "solo" || selected === "smart" || selected === "webtoon" || selected === "cad") && (
        selected === "solo" && choice[key].title === "SOLO 학생용" ? (
          <div className="etc">
            프로모션 상품<br />
            년간 무상 라이선스 제공
          </div>
        ) : (
          choice[key].count > 0 && (
            <div className="etc">{choice[key].count}인 사용(부가세포함)</div>
          )
        )
      )}

      {/* 디버깅용 정보 (필요시 주석 해제) */}
      {/* <div style={{ fontSize: '10px', color: '#999', padding: '5px', textAlign: 'left' }}>
        <p>key: {key}</p>
        <p>count: {choice[key].count}</p>
        <p>price: {choice[key].price}</p>
        <p>monthPrice: {choice[key].monthPrice}</p>
      </div> */}
      <div className="buyBtn">
        <BuyButton product={key} count={choice[key].count} />
      </div>
    </PriceItem>
  ));

  return (
    <PriceBoxContainer>
      <PriceBoxWrapper>
        {ChoiceList}
      </PriceBoxWrapper>
    </PriceBoxContainer>
  );
};

const PriceBoxContainer = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const PriceBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1344px;
  margin: 0 auto;
  
  /* 4개 이하일 때도 동일한 간격 유지 */
  & > * {
    min-width: 0;
  }
  
  /* 항목이 4개 미만일 때 왼쪽 정렬 */
  justify-content: start;
  grid-auto-flow: dense;
`;

const PriceItem = styled.div`
  background: #fff;
  color: #666;
  height: 337px;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #dfddde;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  &:hover {
    background: #ececec;
  }

  .title {
    font-family: HY고딕M, sans-serif;
    height: 60px;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .price {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    & {
      font-family: HY고딕B, sans-serif;
      font-size: large;
    }
    & span {
      font-size: large;
    }

    & .priceRow {
      display: flex;
      width: 150px;

      & .Year {
        font-family: HY고딕M, sans-serif;
        font-size: 13px;
        width: 150px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-items: center;
      }

      & .Price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 140px;
        & span {
          font-size: large;
        }
      }
    }

    & span {
      font-size: 12px;
    }
    & .callus {
      font-size: 18px;
    }
  }

  .desc {
    height: 90px;
    font-family: HY고딕M, sans-serif;
    font-size: 16px;
    /*background-color:yellow;*/
    display: flex;
    justify-content: center;
    align-items: flex-end;
    white-space: pre-wrap;
  }

  .etc {
    font-family: HY고딕M, sans-serif;
    font-size: 10px;
    justify-content: center;
    color: #666;
  }

  .buyBtn {
    height: 114px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: HY고딕B, sans-serif;
      font-size: 21px;
    }
  }
`;

const Btn = styled.div`
  width: 120px;
  height: 40px;
  border-radius: 20px;
  color: white;
  background-color: #f54884;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  &:hover {
    background-color: #e03772;
  }
`;

export default PriceBox;
