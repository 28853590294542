/**
 * Created by jonghwan on 2021-03-16.
 */
import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";

import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { withCookies } from "react-cookie";
import * as type from "../../actions/ActionTypes";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { msg: "" };
  }

  render() {
    const { button, cookies } = this.props;
    const { onCustomer } = this.props;

    // const query_info = location.state;
    // const { email } = query_info;

    const LoginSchema = yup.object({
      email: yup
        .string()
        .email("잘못된 이메일 형식입니다.")
        .required("필수정보 입력 정보입니다."),
      password: yup.string().required("필수 입력 정보 입니다."),
    });

    // console.log("#query");
    // console.log(query_info);

    button.text = "로그인";

    return (
      <Wrapper>
        <div className="container">
          <div className="top-container">
            <h1> 회원 로그인 </h1>
          </div>
          <div className="body-container">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                let email = values.email;
                let password = values.password;

                let req = { email: email, password: password, type: "email" };


                console.log(req)

                axios.post(`${type.API_URL}auth/verify`, req).then(
                  (response) => {
                    // 사용자 정보

                    let res_data = response.data;

                    if (response.data.join === "Y") {
                      let query_data = {
                        query: { ...this.props.location.state },
                      };

                      //서버에서 확인된 사용자 정보
                      onCustomer("PUT", {
                        name: "data",
                        value: { ...res_data, account_type: "email" },
                      });

                      // set coookie
                      // 사용자 유니크 키
                      let m_code = res_data.m_code;
                      cookies.set("m_code", m_code, { path: "/" });

                      // console.log("##### query data ####");
                      // console.log(res_data.m_code);
                      // console.log("#쿠키생성#")

                      axios
                        .get("/auth/sns.php?m_code=" + m_code)
                        .then(function () {
                          // console.log("php session 생성");
                        });

                      // 페이지 이동
                      this.props.history.push("/apply/payment", query_data);
                    } else {
                      this.setState({
                        msg: "아이디 패스워드를 확인 해주세요",
                      });
                    }
                  },
                  (error) => {
                    // console.log("err");
                    // console.log(error);
                  }
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <input
                    name="email"
                    type="email"
                    value={values.email}
                    placeholder="이메일 아이디"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.email && errors.email}
                  />
                  <span className="warn">
                    <ErrorMessage className="warn" name="email" />
                  </span>

                  <input
                    name="password"
                    type="password"
                    placeholder="비밀번호"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                  />

                  <span className="warn">
                    {this.state.msg ? this.state.msg : ""}
                  </span>

                  <FlexCenter>
                    {<Buttons className="login"> 로그인</Buttons>}
                  </FlexCenter>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export const Wrapper = styled.div`
    & + & {
        margin-top: 1rem;
    }
    font-family: HY고딕M, sans-serif;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 750px;

    border: 1px solid;

    .container {
        display: flex;
        width: 450px;
        height: 250px;
        flex-direction: column;
    }

    .title-container {
    }

    .body-container {
        display: inline-flex;        
        flex: 0 1 50%;
        display: flex;
        border: 0px solid;
        }
    }

    form {
        padding: 0px;
        width: 100%;
    }

    h1 {
        font-family: HY고딕M, sans-serif;
        font-size: 22px;
        display: flex;
        align-items: center;
        margin-bottom:30px;
    }

    input {
        width: 80%;
        font-family: HY고딕L, sans-serif;
        border: 1px solid gray};
        outline: none;
        border-radius: 0px;
        line-height: 2.5rem;
        font-size: 16px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;        
    }

    .warn {
        display:block;
        font-size:6px;
        color:red;
        height:17px;
        
    }
`;

export const Message = styled.div`
  & span {
    font-size: 10px;
    color: red;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;

  .kakao {
    --button-bg-color: #fcc728;
  }

  .new {
    --button-bg-color: #00bbd4;
    --button-color: #ffffff;
  }

  .login {
    --button-bg-color: #08cdd4;
    --button-color: #ffffff;
  }
`;

export const Buttons = styled.button`
  --button-hover-bg-color: #08cdd4;

  display: inline-block;
  border: none;
  border-radius: 4px;
  appearance: none;
  text-align: center;
  text-decoration: none;

  width: 260px;
  height: 46px;

  background: var(--button-bg-color);
  color: var(--button-color);

  cursor: pointer;

  padding-top: 10px;
`;

export default withCookies(Login);
