import React from 'react';
import styled from 'styled-components';

// Footer 로고 이미지 import (헤더 로고와 동일)
import footerLogo from '../../assets/img/top-logo.png'; 

const FooterWrapper = styled.div`
  /* Style isolation enhancements */
  box-sizing: border-box; 
  /* font-family: sans-serif; */ /* Consider setting a base font if needed */

  /* Existing styles */
  background-color: #303842; /* 이미지 기반 색상 변경 */
  padding: 25px 0; /* 패딩 조정 */
  font-size: 12px;
  color: #ced4da; /* Base text color for the footer */
  width: 100%; 

  *, *::before, *::after {
    box-sizing: inherit; /* Ensure all descendants inherit box-sizing */
  }
`;

const Container = styled.div`
  /* TODO: 컨테이너 스타일 추가 */
  width: 1344px; 
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */ /* Remove wrap to enforce single line */
`;

const FooterLogo = styled.div`
  /* TODO: 로고 스타일 추가 */
  flex-shrink: 0; /* Prevent shrinking */
  img {
    /* height: 45px; */ /* 이미지 높이 설정 제거 */
    vertical-align: middle; 
  }
`;

const FooterContent = styled.div`
  /* TODO: 콘텐츠 영역 스타일 추가 */
  text-align: left; 
  line-height: 1.8; 
  flex-grow: 1; 
  margin: 0 30px; 
  color: #adb5bd;
  min-width: 0; /* Prevent content overflow from breaking flex */
`;

const Copy = styled.span`
  /* TODO: 카피라이트 스타일 추가 */
  display: block;
  margin-top: 8px; /* 간격 조정 */
  font-size: 11px;
  color: #adb5bd; /* 텍스트 색상 밝게 조정 */
`;

const FooterLink = styled.div`
  /* TODO: 링크 영역 스타일 추가 */
  display: flex;
  align-items: center;
  flex-shrink: 0; /* Prevent shrinking */
`;

const FamilySelect = styled.select`
  /* TODO: Family Site 드롭다운 스타일 추가 */
  margin-right: 15px;
  padding: 8px 10px; /* 패딩 조정 */
  border: 1px solid #5a636d; /* 테두리 색상 변경 */
  border-radius: 3px;
  font-size: 12px;
  background-color: #303842; /* 배경색 변경 */
  color: #a9b0b8; /* 텍스트 색상 변경 */
  -webkit-appearance: none; /* 기본 화살표 제거 (Chrome, Safari) */
  -moz-appearance: none; /* 기본 화살표 제거 (Firefox) */
  appearance: none; /* 기본 화살표 제거 */
  /* TODO: 커스텀 화살표 이미지 또는 아이콘 추가 */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%235a636d%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'); /* 임시 SVG 화살표 */
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 10px auto;
  padding-right: 30px; /* 화살표 공간 확보 */
`;

const FooterSns = styled.div`
  /* TODO: SNS 아이콘 영역 스타일 추가 */
  display: flex;
  gap: 8px; /* 아이콘 간격 조정 */

  a div {
    width: 30px; /* 크기 조정 */
    height: 30px; /* 크기 조정 */
    background-color: transparent; /* 배경 투명 */
    border: 1px solid #5a636d; /* 테두리 추가 */
    border-radius: 50%; /* 원형 */
    display: flex; /* 내부 아이콘 정렬용 */
    justify-content: center;
    align-items: center;
    color: #a9b0b8; /* 아이콘 색상 */
    font-size: 14px; /* 아이콘 폰트 크기 */
    /* TODO: 실제 아이콘 (이미지 또는 아이콘 폰트) 적용 필요 */
  }
  /* 예시: 아이콘 폰트 사용 시 */
  /* .sns_facebook::before { content: '\f09a'; font-family: 'Font Awesome 5 Brands'; } */ 
  /* .sns_blog::before { content: 'B'; font-weight: bold; } */ /* 임시 */
  /* .sns_kakao::before { content: 'K'; font-weight: bold; } */ /* 임시 */
`;


const FooterIndex = () => {
  // Family Site 이동 핸들러
  const handleFamilySiteChange = (event) => {
    const url = event.target.value;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer'); // 보안 강화
    }
    // event.target.value = ""; // 선택 후 기본값으로 되돌리기 (선택사항)
  };

  return (
    <FooterWrapper className="footer">
      <Container className="container">
        <FooterLogo className="footer-logo">
          {/* 로고 경로 수정 */}
          <img src={footerLogo} alt="FONT BADA" />
        </FooterLogo>
        <FooterContent className="footer-content">
          ㉾ 06664. 서울시 서초구 서초대로 118 양지빌딩 5층 한양정보통신 통신판매업신고 번호 : 제 2004-05089호 <br />
          대표전화번호 : 02-598-0050 FAX : 02-598-0906&nbsp;대표:강경수&nbsp;개인정보 책임관리자:박기덕<br />
          <Copy className="copy">ⓒ Hanyang Information &amp; Communications Co., Ltd. All Rights Reserved.</Copy>
        </FooterContent>
        <FooterLink className="footer-link">
          <FamilySelect id="familySite" className="family" onChange={handleFamilySiteChange} defaultValue="">
            <option value="">Family Site</option>
            <option value="https://www.hanyang.co.kr/">(주)한양정보통신</option>
            <option value="https://www.hanyang.co.kr/hygothic/">HYGothic A1/B1/C1</option>
          </FamilySelect>
          <FooterSns className="footer-sns">
            {/* TODO: 실제 아이콘 구현 필요 (아래는 구조만 잡음) */}
            <a href="https://www.facebook.com/FONTBADA" target="_blank" rel="noopener noreferrer"><div className="sns_facebook">f</div></a>
            <a href="https://blog.naver.com/hyfont" target="_blank" rel="noopener noreferrer"><div className="sns_blog">B</div></a>
            <a href="https://pf.kakao.com/_xlxiJmxl" target="_blank" rel="noopener noreferrer"><div className="sns_kakao">k</div></a>
          </FooterSns>
        </FooterLink>
      </Container>
    </FooterWrapper>
  );
};

export default FooterIndex; 