/**
 * Created by Jeongbin on 2017-06-21.
 */
import React, { useEffect } from "react";
// import License from "./License";

import Bank from "./Bank";

import styled from "styled-components";
import axios from "axios";
import * as type from "../../actions/ActionTypes";

import MookHyangCDKey from "./MookHyangCDKey"


const Payment = (props) => {
  const { how, company } = props;
  const { onHow } = props;
  // const [payInit, setPayInit] = useState("card");

  // 사용자의 정보를 조회함
  useEffect(() => {

    const { cookies, onCustomer, onHow } = props;

    let m_code = process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_TEST_M_CODE  // .env.local에서 테스트 코드 사용
      : cookies.get("m_code");

    let req = { m_code: m_code };

    let payInit = company.selected === "soloEdu" ? "promotion" : "card";

    onHow("METHOD", { value: payInit });

    axios.post(`${type.API_URL}auth/verify`, req).then((response) => {
      // 사용자 정보
      let res_data = response.data;

      //서버에서 확인된 사용자 정보
      onCustomer("PUT", {
        name: "data",
        value: { ...res_data },
      });
    });


  }, []);


  //결제방법선택
  const PayMethod = (how) => {
    return Object.keys(how.options).map((key) => (
      <RadioGroup
        className="option"
        key={key}
        hidden={company.type === "month" && key === "vbank" ? true : false}
      >
        <input
          type="radio"
          name="how"
          id={key}
          onChange={(e) => {
            onHow("CHANGE", e.target);
          }}
          value={how.options[key].value}
          checked={how.options[key].checked}
        />
        <label
          htmlFor={key}
          className={key}
          onChange={(e) => {
            onHow("CHANGE", e.target);
          }}
        >
          <div className={`radio-area solo`}>
            <span>
              <span className={company.selected}> {""} </span>
            </span>
          </div>
          <div className="info">
            <p> {how.options[key].label} </p>
          </div>
        </label>
      </RadioGroup>
    ));
  };



  const Trial = () => {
    //onHow("CHANGE", { id: "promotion", value: "promotion" });

    return (
      <RadioGroup className="option">
        <input type="radio" name="how" id="1" value="" defaultChecked />
        <label htmlFor="1" className="1">
          <div className={`radio-area solo`}>
            <span>
              <span className="solo"> {""} </span>
            </span>
          </div>
          <div className="info">
            <div>
              <p> 무료</p>
            </div>
          </div>
        </label>
      </RadioGroup>
    );
  };

  return (
    <Payments>

      <h2> 결제 방법 선택 </h2>

      <FormGroup>
        {

          ["trial", "soloEdu"].includes(company.selected) ?
            (
              <Trial />
            ) : (
              <PayMethod {...how} />
            )
        }
      </FormGroup>


      {

        // only mookhyang

        ["mookhyang"].includes(company.selected) ?

          (<MookHyangCDKey {...props} />) : ""

      }


      {

        ["trial", "soloEdu"].includes(company.selected) && how.selected === "card" ?
          (
            <ul>
              <li>{company.product} 카드결제는 카드결제 영수증으로 대체합니다.</li>
            </ul>
          ) : (
            ""
          )

      }
      {["vbank"].includes(how.selected) ?
        (
          <div>
            <Bank {...props} />
          </div>
        ) :
        (
          ""
        )
      }

    </Payments>
  );
};

const Payments = styled.div`
  width: 465px;
  padding: 40px 0px 40px 0px;

  ul {
    padding: 30px 0px 30px 0px;
  }

  ul li {
    font-family: HY고딕M, sans-serif;
    font-size: 14px;
    list-style-type: square;
    list-style-position: inside;
  }

  & h2 {
    font-family: HY고딕M, sans-serif;
    font-size: 22px;
    line-height: 1;
    // margin-bottom: 35px;
    font-weight: normal;
  }

  input:checked + label .radio-area.webtoon {
    background-color: #fcc728;
  }

  input:checked + label .radio-area.solo {
    background-color: #08cdd4;
  }

  input:checked + label .radio-area.cad {
    background-color: #cde736;
  }

  input:checked + label .radio-area.smart {
    background-color: #e81f61;
  }

  input:checked + label .radio-area span span {
    display: block;
  }

  input {
    display: none;
  }
`;
const FormGroup = styled.div`
  display: flex;  
  flex-direction:column;
  
  
  & .info {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;
    padding-left: 32px;


    & p {
    font-family: HY고딕L, sans-serif;
    font-size: 16px;
    line-height: 1.6;        
        
    }

    .bold {
    font-family: HY고딕M, sans-serif;
    font-size: 18px;
    }

    & .bankNum {
    font-family: HY고딕L,sans-serif;        
    font-size:16px;
    margin: 5px 0;
    }
    
      
  }

  & .radio-area {
    flex-basis: 60px;
    background-color: #edebee;
    display: flex;
    align-items: center;
    justify-content: center;

    span span {
      border-radius: 50%;
      background-color: #000000;
      width: 13px;
      height: 13px;
      display: none;
    }

    span {
      border-radius: 50%;
      background-color: #fefefe;
      width: 23px;
      height: 23px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

  }
  
}`;



const RadioGroup = styled.div`
  flex-basis: 25%;
  display: ${(props) => (props.hidden ? "none" : "flex")};

  border-radius: 3px;
  border: 1px solid #dfddde;
  box-sizing: border-box;

  margin-top:15px;

  & label {
    display: flex;
    flex-grow: 1;
    cursor: pointer;
  }

  & .radio-area {
    flex-basis: 60px;
    background-color: #edebee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card {height:38px;}  
  .vbank {height:38px;}  
  .promotion {height:38px}  
  .trial {height:38px}  
 


}`;


export default Payment;


